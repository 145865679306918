import { Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import HeaderLogo from '@/components//HeaderLogo/HeaderLogo';
import HeaderBasket from '@/components/HeaderBasket/HeaderBasket';
import LanguagePagePicker from '@/components/LanguagePagePicker/LanguagePagePicker';
import LoginActions from '@/components/PageHeader/LoginActions/LoginActions';
import PageMargins from '@/components/ui/PageMargins/PageMargins';

import { InnerWrapper, OuterWrapper } from './LowerBar.style';

const UpperBar = ({
  hasNavigation = true,
  languagePagePickerHidden = false,
  loginMenuHidden = false,
  basketHidden = false,
  actionIcons = null,
}) => {
  const actionElements = [
    { element: <LanguagePagePicker />, visible: !languagePagePickerHidden },
    { element: <LoginActions />, visible: !loginMenuHidden },
    {
      element: <HeaderBasket data-cy="headerBasket desktop" />,
      visible: !basketHidden,
    },
  ].filter(({ visible }) => visible);

  return (
    <OuterWrapper bgcolor="common.white" hasNavigation={hasNavigation}>
      <PageMargins>
        <InnerWrapper
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          hasNavigation={hasNavigation}>
          <HeaderLogo data-cy="link: header logo" />
          <Stack direction="row" alignItems="center">
            {actionIcons}
            {actionElements.map(({ element }, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: 20,
                      mx: 1,
                    }}
                  />
                )}
                {element}
              </React.Fragment>
            ))}
          </Stack>
        </InnerWrapper>
      </PageMargins>
    </OuterWrapper>
  );
};

UpperBar.propTypes = {
  hasNavigation: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  basketHidden: PropTypes.bool,
  loginMenuHidden: PropTypes.bool,
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default UpperBar;
