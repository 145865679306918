import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BottomLink from '@/components/PageHeader/BottomLink/BottomLink';
import Partners from '@/components/PageHeader/Partners/Partners';
import SideSectionCard from '@/components/PageHeader/SideSectionCard/SideSectionCard';

import { getChildren, sortByTitle } from '../../utils';
import MenuItem from '../MenuItem/MenuItem';
import { BackLink, Level, Links } from '../MobileNavigation.style';
import { Footer } from './Level2.style';

const Level2 = ({
  level,
  activeLevel,
  onGoBackClick,
  onMenuItemClick,
  closeNavigation,
}) => {
  const navbarCards = level?.attributes?.sideSection?.navbarCards || [];
  const hasSideSection = navbarCards.length > 0;
  const partners = level?.attributes?.partners?.partners?.data || [];
  const hasPartners = partners.length > 0;
  const bottomLink = level?.attributes?.bottomLink;

  return (
    <Level>
      <Links active={activeLevel === 2}>
        <BackLink onClick={onGoBackClick}>
          {level && (
            <Typography variant="h6Redesign" component="div" color="primary">
              {level.attributes.title}
            </Typography>
          )}
          <CloseIcon color="primary" />
        </BackLink>
        {sortByTitle(getChildren(level)).map((item, index) => (
          <MenuItem
            item={item}
            onClick={onMenuItemClick}
            closeNavigation={closeNavigation}
            key={index}
          />
        ))}
      </Links>
      <Footer active={activeLevel === 2}>
        {(hasPartners || bottomLink) && (
          <Box mb={6.25} px={2}>
            {partners.length > 0 && (
              <Stack mb={3.5}>
                <Partners partners={partners} />
              </Stack>
            )}
            {bottomLink && (
              <BottomLink bottomLink={bottomLink} onClick={closeNavigation} />
            )}
          </Box>
        )}
        {hasSideSection && (
          <Stack px={2} py={8} bgcolor="primaryColorShades.p4" spacing={16}>
            {navbarCards.map((card, index) => (
              <SideSectionCard
                card={card}
                key={index}
                onButtonClick={closeNavigation}
              />
            ))}
          </Stack>
        )}
      </Footer>
    </Level>
  );
};

Level2.propTypes = {
  level: PropTypes.object,
  activeLevel: PropTypes.number.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  closeNavigation: PropTypes.func.isRequired,
};

export default Level2;
