import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import { useAuth } from '@/hooks/useAuth';

import LoginActionsDesktop from './LoginActionsDesktop/LoginActionsDesktop';
import LoginActionsMobile from './LoginActionsMobile/LoginActionsMobile';

const LoginActions = ({ desktop = true, onAction }) => {
  const router = useRouter();
  const { pathname } = router;
  const { authenticated, logOut } = useAuth();

  const handleLogout = () => {
    logOut();
    onAction?.();
  };

  const handleGoToPage = path => {
    if (pathname !== path) {
      router.push(path);
    }
    onAction?.();
  };

  return desktop ? (
    <LoginActionsDesktop
      onLogout={handleLogout}
      onGoToPage={handleGoToPage}
      authenticated={authenticated}
      pathname={pathname}
      loginPagePath="/login"
    />
  ) : (
    <LoginActionsMobile
      onLogout={handleLogout}
      onGoToPage={handleGoToPage}
      authenticated={authenticated}
      pathname={pathname}
      loginPagePath="/login"
    />
  );
};

LoginActions.propTypes = {
  desktop: PropTypes.bool,
  onAction: PropTypes.func,
};

export default LoginActions;
