import PropTypes from 'prop-types';
import React from 'react';

import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { useNavigationContext } from '@/hooks/useNavigationContext';

import DesktopHeader from './DesktopHeader/DesktopHeader';
import MobileHeader from './MobileHeader/MobileHeader';

const PageHeader = ({
  basketHidden = false,
  languagePagePickerHidden = false,
  navigationHidden = false,
  loginMenuHidden = false,
  actionIcons,
  phoneNumber,
  mrktClassNamePhone,
  mrktClassNameMail,
}) => {
  const navigationState = useNavigationContext();
  const navigation = navigationHidden ? null : navigationState;
  const mobileMediaQuery = useMobileMediaQuery();

  return mobileMediaQuery ? (
    <MobileHeader
      basketHidden={basketHidden}
      languagePagePickerHidden={languagePagePickerHidden}
      loginMenuHidden={loginMenuHidden}
      navigation={navigation}
      actionIcons={actionIcons}
      phoneNumber={phoneNumber}
      mrktClassNamePhone={mrktClassNamePhone}
      mrktClassNameMail={mrktClassNameMail}
    />
  ) : (
    <DesktopHeader
      basketHidden={basketHidden}
      languagePagePickerHidden={languagePagePickerHidden}
      loginMenuHidden={loginMenuHidden}
      navigation={navigation}
      actionIcons={actionIcons}
      phoneNumber={phoneNumber}
      mrktClassNamePhone={mrktClassNamePhone}
      mrktClassNameMail={mrktClassNameMail}
    />
  );
};

PageHeader.propTypes = {
  /** Should hide basket */
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  navigationHidden: PropTypes.bool,
  loginMenuHidden: PropTypes.bool,
  /** Adds additional icons in front of the default ones */
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* Overwrites default phone number */
  phoneNumber: PropTypes.string,
  /* Class names used for tracking */
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

export default PageHeader;
