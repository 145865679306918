import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { formatPhoneToLink } from '@/helpers/utils';
import usePhoneNumber from '@/hooks/usePhoneNumber';

import { IconNewHeader, Wrapper } from './HeaderPhone.style';

const HeaderPhone = ({ phoneNumber: phone, mrktClassName, onClick }) => {
  const [phoneNumber, phoneNumberLink] = usePhoneNumber();

  return (
    <Wrapper
      component="a"
      variant="body1"
      href={phone ? formatPhoneToLink(phone) : phoneNumberLink}
      data-cy="link: phone desktop"
      className={mrktClassName || 'mrkt-hp_phone'}
      color="common.white"
      onClick={onClick}>
      <IconNewHeader name="phone" fontSize="small" />
      <Typography
        component="span"
        color="inherit"
        fontSize={theme => theme.typography.pxToRem(12)}
        ml={1}
        className="mrsCallTracking">
        {phone || phoneNumber}
      </Typography>
    </Wrapper>
  );
};

HeaderPhone.propTypes = {
  /*Overwrites phone from usePhoneNumber*/
  phoneNumber: PropTypes.string,
  mrktClassName: PropTypes.string,
  onClick: PropTypes.func,
};

HeaderPhone.defaultProps = {
  phoneNumber: null,
  mrktClassName: null,
};

export default HeaderPhone;
