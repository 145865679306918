import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ContactFormDialog from '@/components/ContactFormDialog/ContactFormDialog';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { EmailIconButton } from './HeaderEmail.style';

const HeaderEmail = ({ mrktClassName }) => {
  const formatMessage = useFormatMessage();
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const mobileMediaQuery = useMobileMediaQuery();

  const handleOpen = () => setContactFormOpen(true);

  const handleClose = () => setContactFormOpen(false);

  return (
    <>
      <EmailIconButton
        aria-label="email"
        onClick={handleOpen}
        title="Email"
        data-cy="headerEmailIcon"
        className={mrktClassName}>
        {!mobileMediaQuery ? (
          <>
            <SvgIcon
              name="mail"
              sx={{ color: theme => theme.palette.common.white }}
              size="small"
            />
            <Typography
              color="common.white"
              fontSize={theme => theme.typography.pxToRem(12)}
              ml={1}>
              {formatMessage('header_email')}
            </Typography>
          </>
        ) : (
          <SvgIcon name="mail" color="primary" />
        )}
      </EmailIconButton>
      <ContactFormDialog open={contactFormOpen} onClose={handleClose} />
    </>
  );
};

HeaderEmail.propTypes = {
  mrktClassName: PropTypes.string,
};

HeaderEmail.defaultProps = {
  mrktClassName: null,
};

export default HeaderEmail;
